/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // SUBPAGES MOBILE
        var n_subpages = $(".subpages .pages-list .menu-item").length;
        var class_n_subpages = n_subpages;
        $(".subpages .pages-list .menu-item").addClass("page_item_of_" + class_n_subpages);

        // STORE LOCATOR (JC)
        if ($(".module-store-locator").length) {
          $("#bh-sl-map-container").storeLocator({
            // Replacement marker image used for all locations
            'markerImg': '/app/themes/hidroth/dist/images/icon-marker-map.png',
            // Replacement marker dimensions object
            // ex value: { height: 20, width: 20 }
            'markerDim': {height: 19, width: 16},
            // The unit of length. Default is m for miles.
            // Change to km for kilometers.
            'lengthUnit': 'km',
            // The format of the data source.
            // Accepted values include kml, xml, json, and jsonp.
            'dataType': 'json',
            // Accepts raw KML, XML, or JSON instead of using a remote file.
            'dataRaw': null,
            // The path to the location data.
            'dataLocation': '/app/themes/hidroth/data/storelocatormin.json',
            // First hides the map container and then uses jQuery’s slideDown method to reveal the map.
            'slideMap': false,
            // If true, the map will load with a default location immediately.
            // Set slideMap to false if you want to use this.
            'defaultLoc': true,
            // If using defaultLoc, set this to the default location latitude.
            'defaultLat': '40.415363',
            // If using defaultLoc, set this to the default location longitude.
            'defaultLng': '-3.707398',
            // Set to true if you want to use the HTML5 geolocation API (good for mobile) to geocode the user's location.
            'autoGeocode': true,
            // ID of list template if using inline Handlebar templates instead of separate files.
            'listTemplateID': 'listTemplate',
            // ID of infowindow template if using inline Handlebar templates instead of separate files.
            'infowindowTemplateID': 'infoTemplate',
            'mapSettings': {zoom: 0}
          });
        }

        // MENU MOBILE TOGGLE BUTTON (JC)
        $("#nav-toggle").click(function () {
          $(this).toggleClass("active");
          $(".nav-main-mobile").toggleClass("active");
        });

        // HEADER SCROLL (JC)
        $(window).on("scroll touchmove", function () {
          var h_header_wrapper = $('.header-wrapper').height();
          var h_subheader = $('.sub-header').height() === undefined ? 0 : $('.sub-header').height();
          var h_headers = h_header_wrapper + h_subheader;
          $('.header-wrapper').toggleClass('shrink', $(document).scrollTop() > h_headers);
          $('.sub-header').toggleClass('shrink', $(document).scrollTop() > h_headers);
        });

        // CAROUSEL VIDEO TESTIMONIAL (NAV FOR .carousel-testimonial) (JC)
        $('.carousel-video-testimonial').slick({
          arrows: false,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          rows: 0,
          responsive: [
            {
              breakpoint: 576,
              settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                infinite: true,
                rows: 0
              }
            }
          ]
        });


        $('.collapse').on('shown.bs.collapse', function (e) {
          var $card = $(this).closest('.card');
          $('html,body').animate({
            scrollTop: $card.offset().top - 50
          }, 500);
        });

        /* Pain test */
        $('#carouselPainTest').carousel({
          interval: false,
          wrap: false
        })
          .on('slide.bs.carousel', function (e) {
            var slide_to = $(e.relatedTarget).attr('id');
            var id_slide = slide_to.split("-")[1];
            var $curr = $("#progressBar  a[data-slide-to='" + id_slide + "']").parent();

            if (e.relatedTarget.id == "final_slide") {
              $('.carousel-control-next').addClass('d-none');
              $('.checkout-wrap').addClass('d-none');
            } else if (e.relatedTarget.id == "first_slide") {
              $('.carousel-control-prev').addClass('d-none');
              $('.carousel-control-next').addClass('d-none');
              $('.checkout-wrap').addClass('d-none');
            } else if (e.relatedTarget.id == "thankyou_slide") {
              $('.carousel-control-prev').addClass('d-none');
            }
            // The rest
            else {
              $('#progressBar li').removeClass();
              $curr.addClass("active");
              $curr.prevAll().addClass("visited");
              if (id_slide == '6') {
                $curr.addClass("lastone");
              }
              $('.carousel-control-prev').removeClass('d-none');
              $('.carousel-control-next').removeClass('d-none');
              $('.checkout-wrap').removeClass('d-none');
            }
            dataLayer.push({'virtual_page_url': '/test-de-dolor/' + slide_to});
          });

        $('.accordion-pain-test').slick({
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          adaptiveHeight: true
        });

        $('#intensityBar li').css('cursor', 'pointer')
          .click(function (e) {
            var slide_to = e.currentTarget.id;
            var id_slide = slide_to.split("-")[1];
            var $curr = $("#intensityBar  a[data-slide-to='" + id_slide + "']").parent();
            $('#intensityBar li').removeClass();
            $curr.addClass("active");
            $curr.prevAll().addClass("visited");
            if (id_slide == '10') {
              $curr.addClass("lastone");
            }
            $("input[name='intensity']").val(id_slide);
            $("input[name='intensity']").valid();
          });

        $('.causas label').click(function () {
          var $checkbox = $(this).prev('group-1'), checked = $checkbox.is(':checked');
          $checkbox.find('.group-2').prop('disabled', checked);
          $checkbox.prop('disabled', false);
        });

        $('.collapse.first-step').on('shown.bs.collapse', function (e) {
          var $card = $(this).closest('.card');
          $('html,body').animate({
            scrollTop: $card.offset().top - 150
          }, 500);
        });

        // COMMENTS VALIDATE
        $("#commentform").validate({
          ignore: ":hidden",
          rules: {
            // VALIDATE NAME
            author: {
              required: true
            },
            // VALIDATE EMAIL
            email: {
              required: true,
              email: true
            },
            // VALIDATE COMMENT
            comment: {
              required: true
            }
          },
          errorPlacement: function (error, element) {
            error.insertAfter(element);
          }
        });

        // TEST DEL DOLOR
        //TEST DEL DOLOR - VALIDATE FORM
        var pt_form_validator = $(".pt-form").validate({
          ignore: ":hidden",
          rules: {
            // VALIDATE NAME
            name: {
              required: true
            },
            // VALIDATE EMAIL
            emailAddress: {
              required: true,
              email: true
            },
            // 1. VALIDATE PAIN POINTS
            points: {
              required: true
            },
            // 2. VALIDATE INTENSITY
            intensity: {
              required: true
            },
            // 2. VALIDATE INTENSITY
            "pain-rec": {
              required: true
            },
            // 3. VALIDATE PERIODICITY
            periodicity: {
              required: true
            },
            // 4. VALIDATE BEHAVIOR
            "pain-how-is": {
              required: true
            },
            // 5. VALIDATE CAUSES
            "pain-causes": {
              required: true
            },
            // 6. VALIDATE ACTIVITIES
            activities: {
              required: true
            },
            // VALIDATE CONDITIONS
            check_conditions: {
              required: {
                depends: function (element) {
                  return $('.require-one:checked').size() == 0;
                }
              }
            }
          },
          messages: {
            name: "Este campo es necesario",
            years: "Este campo es necesario",
            // VALIDATE EMAIL
            emailAddress: "Este campo es necesario",
            // 1. VALIDATE PAIN POINTS
            points: "ES NECESARIO QUE SELECCIONES AL MENOS UN PUNTO.",
            // 2. VALIDATE INTENSITY
            intensity: "ES NECESARIO QUE SELECCIONES UNA INTENSIDAD DE DOLOR.",
            // 2. VALIDATE INTENSITY
            "pain-rec": "ES NECESARIO QUE SELECCIONES UNA OPCIÓN.",
            // 3. VALIDATE PERIODICITY
            periodicity: "ES NECESARIO QUE SELECCIONES UNA OPCIÓN.",
            // 4. VALIDATE BEHAVIOR
            behavior: "ES NECESARIO QUE SELECCIONES UNA OPCIÓN.",
            // 5. VALIDATE CAUSES
            "pain-how-is": "ES NECESARIO QUE SELECCIONES UNA OPCIÓN.",
            // 6. VALIDATE ACTIVITIES
            "pain-causes": "ES NECESARIO QUE SELECCIONES AL MENOS UNA OPCIÓN.",
            // VALIDATE CONDITIONS
            check_conditions: "Selecciona al menos un opción"
          },
          errorPlacement: function (error, element) {
            if (element.is(":radio") || element.is(":checkbox") || element.is(":text")) {
              switch (element.attr("name")) {
                case 'points':
                  error.appendTo(element.parents('.checkbox-img.checkbox-img-m'));
                  break;
                case 'intensity':
                case 'pain-rec':
                case 'periodicity':
                case 'pain-causes':
                case 'pain-how-is':
                  error.appendTo(element.parents('.card-block'));
                  break;
                case 'check_conditions':
                  error.appendTo(element.parents('.checkbox-accept'));
                  break;
                default:
                  error.insertAfter(element);
              }
            } else { // This is the default behavior
              error.insertAfter(element);
              //element.parents('.card').addClass("card-error");
            }
          }
        });

        // Cycles to the next item
        $(".carousel-control-next").click(function () {
          if ($(".pt-form").valid()) {
            $("#carouselPainTest").carousel('next');

          }
        });

        $('.pt-form input[type=radio]').change(function () {
          var radioName = $(this).attr('name');

          if (radioName !== 'pain-how-is' && radioName !== 'pain-rec') {
            return;
          }
          var notChecked = $('input:radio:not(":checked")[name=' + radioName + ']');
          var checked = $('input:radio[name=' + radioName + ']:checked');

          notChecked.each(function () {
            var data_not_checked = $(this).attr('data-not-checked');
            var data_checked = $(this).attr('data-checked');
            var img = $(this).parent().children('label').children('img');
            var original_src = img.attr('src');
            var src = original_src.replace(data_checked, data_not_checked);
            img.attr('src', src);
          });

          checked.each(function () {
            var data_not_checked = $(this).attr('data-not-checked');
            var data_checked = $(this).attr('data-checked');
            var img = $(this).parent().children('label').children('img');
            var original_src = img.attr('src');
            var src = original_src.replace(data_not_checked, data_checked);
            img.attr('src', src);
          });
        });

        $('.pt-form input[type=checkbox]').change(function () {
          var checkboxName = $(this).attr('name');

          if (checkboxName !== 'pain-causes') {
            return;
          }

          var data_not_checked = $(this).attr('data-not-checked');
          var data_checked = $(this).attr('data-checked');
          var img = $(this).parent().children('label').children('img');
          var original_src = img.attr('src');
          var src = '';
          if ($(this).is(":checked")) {
            src = original_src.replace(data_not_checked, data_checked);
          } else {
            src = original_src.replace(data_checked, data_not_checked);
          }
          img.attr('src', src);
        });


        // TEST DEL DOLOR - SUBMIT FORM
        $(".pt-form").submit(function (event) {
          event.preventDefault();
          if ($(".pt-form").valid()) {
            $('.send-test-form').hide();
            $('.loading').show();
            var points_group = $("input[name='points']:checked").map(function () {
              return this.value;
            }).get();
            var causes_group = $("input[name='causes']:checked").map(function () {
              return this.value;
            }).get();
            var activities_group = $("input[name='activities']:checked").map(function () {
              return this.value;
            }).get();
            if ($('input[name="optinNewsletter"]').is(":checked") || $('input[name="optinDirectMarketing"]').is(":checked")) {
              var send_mail = false;
            } else {
              var send_email = true;
            }
            $.ajax({
              type: "POST",
              url: ajaxVarsHd.ajaxUrl,
              dataType: 'json',
              data: {
                'action': 'send_paintest',
                'nonce': ajaxVarsHd.nonce,
                'name': $('input[name="firstName"]').val(),
                'email': $('input[name="emailAddress"]').val(),
                'points': points_group,
                'intensity': $('input[name="intensity"]').val(),
                'periodicity': $('input[name="periodicity"]:checked').val(),
                'behavior': $('input[name="pain-how-is"]:checked').val(),
                'pain-rec': $('input[name="pain-rec"]:checked').val(),
                'causes': causes_group,
                'activities': activities_group,
                'send_email': send_email,
                'especial_form': $('input[name="especialForm"]').val()
              },
              success: function (data) {
                $('input[name="singleLineText"]').val(data.result);
              }
            }).done(function () {
              if ($('input[name="optinNewsletter"]').is(":checked") || $('input[name="optinDirectMarketing"]').is(":checked")) {
                var pain_test_form = $(".pt-form");
                var pain_test_url = pain_test_form.attr('action');
                var pain_test_data = $('.pt-form .to_send').serialize();
                $.post(pain_test_url, pain_test_data).done(function (data) {
                  $('#carouselPainTest').carousel("next");
                });
              } else {
                $('#carouselPainTest').carousel("next");
              }
              dataLayer.push({'virtual_page_url': '/test-de-dolor/thankyou_slide'});
            });
          } else {
            return false;
          }
        });
        /* MOVE PAIN TEST SUCCESS */
        $(".pain-test-success").insertBefore($(".title-page"));

        // NEWSLETTER SUBSCRIBED - VALIDATE FORM
        $(".newsletter-form").validate({
          ignore: ":hidden",
          rules: {
            firstName: {
              required: true
            },
            emailAddress: {
              required: true,
              email: true
            },
            optinPlatformComm: {
              required: true
            }
          },
          messages: {
            firstName: "Este campo es necesario",
            emailAddress: "Este campo es necesario",
            optinPlatformComm: "Este campo es necesario"
          },
          errorPlacement: function (error, element) {
            if (element.is(":radio") || element.is(":checkbox")) {
              if (element.attr("name") === "check_conditions") {
                error.appendTo(element.parents('.checkbox'));
              } else {
                error.appendTo(element.parents('.checkbox'));
              }
            } else { // This is the default behavior
              error.insertAfter(element);
            }
          }
        });
        // NEWSLETTER SUBSCRIBED - SUBMIT FORM
        $(".newsletter-form").submit(function (event) {
          event.preventDefault();
          if ($(".newsletter-form").valid()) {
            var $form_news = $(this);
            var url = $form_news.attr('action');
            var data = $(this).serialize();
            var posting = $.post(url, data);
            posting.done(function (data) {
              $('.newsletter-form').html('<div class="newsletter-subscribed"><div class="container"><div class="row"><div class="col-md-6 push-md-3"></div></div></div></div>');
              $('.newsletter-subscribed').html(
                '<div class="icon-newsletter"></div>' +
                '<h3>¡Muchas gracias por suscribirte!</h3>' +
                '<p>Recibirás un email en el correo indicado con la confirmación de tu subscripción.</p>'
              );
            });
          } else {
          }
        });

        /// MORE POSTS (AJAX)
        // CLICK
        $('.more-posts').after('<span class="posts_loadmore"><i class="fa fa-plus" aria-hidden="true"></i></span>');

        var button = $('.posts_loadmore');
        var page = 2;
        var loading = false;

        $(".posts_loadmore").click(function () {
          if (!loading) {
            loading = true;
            var data = {
              action: 'be_ajax_load_more',
              nonce: ajaxVarsHd.nonce,
              page: page,
              query: ajaxVarsHd.query
            };
            $.post(ajaxVarsHd.ajaxUrl, data, function (res) {
              if (res.success) {
                $('.more-posts .container .row').append(res.data);
                $('.more-posts').after(button);
                page = page + 1;
                loading = false;
              } else {
                // console.log(res);
              }
            }).fail(function (xhr, textStatus, e) {
              // console.log(xhr.responseText);
            });
          }
        });


        // Landings forms
        $landingForm = $(".lap-form");
        $landingForm.validate({
          ignore: ":hidden",
          rules: {
            // VALIDATE NAME
            name: {
              required: true
            },
            // VALIDATE EMAIL
            emailAddress: {
              required: true,
              email: true
            },
            // VALIDATE CONDITIONS
            check_conditions: {
              required: {
                depends: function (element) {
                  return $('.require-one:checked').size() === 0;
                }
              }
            }
          },
          messages: {
            name: "Este campo es necesario",
            emailAddress: "Este campo es necesario",
            // VALIDATE CONDITIONS
            check_conditions: "Selecciona al menos un opción"
          },
          errorPlacement: function (error, element) {
            if (element.is(":radio") || element.is(":checkbox") || element.is(":text")) {
              switch (element.attr("name")) {
                case 'check_conditions':
                  error.appendTo(element.parents('.checkbox-accept'));
                  break;
                default:
                  error.insertAfter(element);
              }
            } else { // This is the default behavior
              error.insertAfter(element);
            }
          }
        });

        $landingForm.submit(function (event) {
          event.preventDefault();
          if ($landingForm.valid()) {
            var eloqua_url = $landingForm.attr('action');
            $('.send-test-form').hide();
            $('.loading').show();
            var send_email = !$('.lap-form :input[name="optinNewsletter"]').is(":checked");
            $.ajax({
              type: "POST",
              url: ajaxVarsHd.ajaxUrl,
              dataType: 'json',
              data: {
                'action': 'landing_pain',
                'nonce': ajaxVarsHd.nonce,
                'name': $('.lap-form :input[name="firstName"]').val(),
                'email': $('.lap-form :input[name="emailAddress"]').val(),
                'send_email': send_email,
                'landing_page': $('.lap-form :input[name="landingPageTitle"]').val()
              },
              success: function (data) {
                $('.lap-form :input[name="plan_rec"]').val(data.result);

              }
            }).done(function (data) {
              var thankyou_url = data.page_url;
              if (send_email) {
                dataLayer.push({'event': 'landing_captacion', 'source_lap':'in_site'});

                window.location = thankyou_url+'?tag='+data.landing_type;
              } else {
                dataLayer.push({'event': 'landing_captacion', 'source_lap':'eloqua'});
                var now = new Date();
                var birth_date = new Date(now.getFullYear() - parseInt($('#years').val()), 0, 1);
                var landing_data = $('.lap-form .to_send').serialize();
                landing_data = landing_data + '&birthDate=' + birth_date.toLocaleDateString('Es-es');
                $.post(eloqua_url, landing_data).done(function () {
                  window.location = thankyou_url+'?tag='+data.landing_type;
                });
              }
            });
          }
        });
        $('.farmacia-online').on('click', function (event) {
          var link = event.target.attributes.href.value;
          $('.modal-continuar').attr('href',link);
        });
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        // SLIDER HOME
        if ($('.slider-home .slider-home-element').length > 1) {
          $('.slider-home').slick({
            arrows: false,
            dots: true,
            infinite: true,
            rows: 0,
            slidesToShow: 1
          });
        } else {
          $('.slider-home').slick({
            arrows: false,
            dots: false,
            infinite: true,
            rows: 0,
            slidesToShow: 1
          });
        }

        // CAROUSEL COLUMNS HOME (JC)
        $('.carousel-columns').slick({
          arrows: false,
          dots: false,
          slidesToShow: 4,
          slidesToScroll: 4,
          centerMode: false,
          infinite: false,
          rows: 0,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                infinite: false,
                rows: 0,
                slide: 'div.carousel-columns-item',
                customPaging: function (slider, i) {
                  var title = $(slider.$slides[i]).data('title');
                  return '<h3>' + title + '</h3>';
                },
                appendDots: '.carousel-navigation'
              }
            }
          ]
        });

        $('.carousel-columns').on('setPosition', function () {
          $(this).find('.slick-slide').height('auto');
          var slickTrack = $(this).find('.slick-track');
          var slickTrackHeight = $(slickTrack).height();
          $(this).find('.slick-slide').css('height', slickTrackHeight + 'px');
        });
        $(window).resize(function () {
          $('.carousel-columns').slick('resize');
          $('.carousel-columns').on('setPosition', function () {
            $(this).find('.slick-slide').height('auto');
            var slickTrack = $(this).find('.slick-track');
            var slickTrackHeight = $(slickTrack).height();
            $(this).find('.slick-slide').css('height', slickTrackHeight + 'px');
          });
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
